import React, { useEffect, useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Container/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const PressBlockContainer = styled.div`
  margin: 64px auto 241px;
  @media (min-width: ${breakpoints.md}) {
    margin: 165px auto 170px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: 29px;
  max-width: 1040px;
  margin: auto;
  @media (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 43px;
  }
`

const PressCard = styled.div`
  padding: 47px 30px 20px;
  border-radius: 15px;
  border: 1px solid ${colors.grey};
  min-height: calc(100vw - 56px);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  transition: all 0.25s ease-in;
  :hover {
    background: ${(props) => props.color};
  }
  @media (min-width: 500px) {
    min-height: calc(50vw - 99px);
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: calc(1040px / 3);
  }
`

const Logo = styled.img`
  height: 18px;
`

const Snippet = styled.div`
  ${fonts.canelaThin};
  font-size: 24px;
  line-height: 1.2;
  margin-top: 25px;
  min-width: 100%;
  text-align: center;
`

const PressBlock = (props) => {
  const { pressList } = props
  const [colorIndex, setColorIndex] = useState(0)
  const [hoverIndex, setHoverIndex] = useState(0)

  const background = [`${colors.green}`, `${colors.peach}`, `${colors.clay}`]


  useEffect(() => {
    if (colorIndex === background.length - 1) {
      setColorIndex(0)
    } else {
      setColorIndex(colorIndex + 1)
    }
  }, [hoverIndex])

  return (
    <PressBlockContainer>
      <ContentContainer>
        <Grid>
          {pressList.map((press, index) => {
            const { logo, content, author } = press
            return (
              <PressCard
                key={index}
                color={background[colorIndex]}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
              >
                <Logo src={logo.file.url} alt={author} />
                <Snippet>{renderRichText(content)}</Snippet>
              </PressCard>
            )
          })}
        </Grid>
      </ContentContainer>
    </PressBlockContainer>
  )
}

export default PressBlock
