import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/Press/PageContents"

const PressPage = (props) => {
  const { data, location } = props
  const path = location.pathname
  const { page } = data
  const { metaTitle, metaDescription } = page
  
  return (
    <Layout>
      <Seo title={metaTitle} description={metaDescription} path={path} />
      <PageContents blocks={page.blocks} />
    </Layout>
  )
}

export default PressPage

export const pageQuery = graphql`
  query PressPageQuery {
    page: contentfulPage(slug: { eq: "press" }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulHeroBlock {
          id
          header
          subText {
            raw
          }
          ctaLabel
          ctaLink
          hero {
            gatsbyImageData(width: 1920)
          }
          mobileHero {
            gatsbyImageData(height: 808)
          }
        }
        ... on ContentfulPress {
          author
          logo {
            file {
              url
            }
          }
          content {
            raw
          }
        }
      }
    }
  }
`
