import React from "react"
import HeroBlock from "../Core/HeroBlock"
import PressBlock from "./PressBlock"

const PageContents = (props) => {
  const { blocks } = props
  let pageContents = []
  let press = []

  blocks.forEach((block, index) => {
    const { __typename: type } = block

    if (type === "ContentfulHeroBlock") {
      pageContents.push(<HeroBlock {...block} key={index} />)
    }
    if (type === "ContentfulPress") {
      press.push(block)
    }
    if (
      press.length !== 0 &&
      index === blocks.map((el) => el.__typename).lastIndexOf("ContentfulPress")
    ) {
      pageContents.push(<PressBlock pressList={press} key={index} />)
    }
  })

  return <>{pageContents}</>
}

export default PageContents
